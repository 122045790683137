import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {CHARCOAL} from '../../../../shared/style/colors';
import {GITHUB_STACK_FILE_MARKETPLACE} from '../../../../shared/constants/link';

const ComponentContainer = glamorous.div(({customStyle}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  ...customStyle
}));

const Header = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 17,
  lineHeight: '28px',
  maxWidth: 700,
  color: CHARCOAL,
  textAlign: 'center',
  '& > div': {
    display: 'block'
  }
});

const Image = glamorous.img({
  height: 350,
  width: 700
});

const Section = ({description, imageUrl}) => (
  <ComponentContainer>
    <Header>
      <div>{description}</div>
    </Header>
    <a href={GITHUB_STACK_FILE_MARKETPLACE} target="_blank" rel="noopener noreferrer">
      <Image src={imageUrl} />
    </a>
  </ComponentContainer>
);

Section.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string
};

export const steps = [
  {
    id: 0,
    description:
      'The free plan that you were on was sunsetted on Apr 30. Please use Stack File to keep up with the changes in your repositories',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/tech_stack_docs_compressed.png',
    hidden: false
  },
  {
    id: 1,
    description:
      'Stack File gives you automated tech stack docs - data + visualization that live in your repos',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/tech_stack_docs_compressed.png',
    hidden: false
  },
  {
    id: 2,
    description:
      'The app auto-creates a YML data file called techstack.yml that lists out the entire tech stack of a repo',
    imageUrl: 'https://img.stackshare.io/fe/stack-file-carousel-images/yml_file_compressed.png',
    hidden: false
  },
  {
    id: 3,
    description:
      'The app also creates a MD file to visualize the data in the YML file so that anyone viewing the repo can easily see the tech stack at a glance',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/markdown_file_compressed.png',
    hidden: false
  },
  {
    id: 4,
    description:
      'A README snippet is also auto-created so you can list out the main tech stack being used in a repo in your main README with no manual effort',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/readme_snippet_compressed.png',
    hidden: false
  },
  {
    id: 5,
    description:
      'All of this is automated via PRs from stacksharebot that update the files once per week',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/automated_pull_requests_compressed.png',
    hidden: false
  },
  {
    id: 6,
    description:
      'Get a sample YML file for any public repo and learn more about the Tech Stack File',
    imageUrl:
      'https://img.stackshare.io/fe/stack-file-carousel-images/introducing_tech_stack_file_compressed.png',
    hidden: false
  }
]
  .filter(section => !section.hidden)
  .map(section => <Section key={section.id} {...section} />);
