import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Carousel from './carousel';
import {steps} from './steps';
import {CurrentUserContext} from '../../../../shared/enhancers/current-user-enhancer';
import {isStackFileManagementUser} from '../../../../shared/utils/user-plans';
import gql from 'graphql-tag';
import {ApolloContext} from '../../../../shared/enhancers/graphql-enhancer';

const showEnterpriseFreeSunsetModalQuery = gql`
  query showEnterpriseFreeSunsetModal {
    showEnterpriseFreeSunsetModal
  }
`;

const StackFileCarousel = ({mobile, show = false, onDismiss = null, removeFirstStep = true}) => {
  const client = useContext(ApolloContext);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showModal, setShowModal] = useState(!!show);
  const [showEnterpriseFreeSunsetModal, setShowEnterpriseFreeSunsetModal] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const pagesToHideCarousel = ['/onboarding', '/stack-file-management'];
  const hideCarouselCondition = pagesToHideCarousel.some(key =>
    window.location.pathname.includes(key)
  );

  useEffect(() => {
    if (localStorage.getItem('show_carousel') === null && !hideCarouselCondition) {
      setShowCarousel(true);
    }

    if (localStorage.getItem('show_carousel') === 'false') {
      setShowCarousel(false);
    }

    if (currentUser) {
      client
        .query({query: showEnterpriseFreeSunsetModalQuery})
        .then(({data}) => {
          setShowEnterpriseFreeSunsetModal(data.showEnterpriseFreeSunsetModal);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          setShowEnterpriseFreeSunsetModal(false);
        });
    }
  }, [currentUser]);

  const carouselCondition =
    (showCarousel && !isStackFileManagementUser(currentUser) && showEnterpriseFreeSunsetModal) ||
    showModal;

  return (
    <>
      {carouselCondition && !mobile && (
        <Carousel
          onDismiss={() => {
            setShowCarousel(false);
            setShowModal(false);
            if (onDismiss) onDismiss();
            localStorage.setItem('show_carousel', false);
          }}
          steps={removeFirstStep ? steps.slice(1) : [...steps]}
          setShowCarousel={setShowCarousel}
        />
      )}
    </>
  );
};

StackFileCarousel.propTypes = {
  mobile: PropTypes.bool,
  show: PropTypes.bool,
  onDismiss: PropTypes.func,
  removeFirstStep: PropTypes.bool
};

export default StackFileCarousel;
