import React, {useState} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {withPortal} from '../../../../shared/library/modals/base/portal';
import {
  WHITE,
  FOCUS_BLUE,
  DODGER_BLUE,
  SILVER_ALUMINIUM,
  WHITE_SMOKE,
  GREY
} from '../../../../shared/style/colors';
import Arrow from '../../../../shared/library/icons/right-arrow-white.svg';
import GithubIcon from '../../../../shared/library/icons/github-icon.svg';
import SimpleButton from '../../../../shared/library/buttons/base/simple';
import {ALPHA} from '../../../../shared/style/color-utils';
import BaseModal from '../../../../shared/library/modals/base/modal.jsx';
import {BASE_TEXT, FONTS, WEIGHT} from '../../../../shared/style/typography';
import {PHONE} from '../../../../shared/style/breakpoints';
import {GITHUB_STACK_FILE_MARKETPLACE} from '../../../../shared/constants/link';
import {useSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import CloseIcon from '../../../../shared/library/icons/close.svg';

const Container = glamorous.div({
  width: '100%',
  height: 620,
  borderRadius: 4,
  backgroundColor: WHITE,
  position: 'relative',
  padding: '10px 26px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});

const Actions = glamorous.div({
  borderTop: '1px solid #dadada',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 30,
  alignItems: 'center'
});

const Previous = glamorous(SimpleButton)(({show}) => ({
  visibility: show ? 'visible' : 'hidden',
  background: WHITE,
  borderColor: WHITE,
  fontSize: 15,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.25,
  color: '#424242',
  '& > svg': {
    width: 8,
    height: 13,
    marginRight: 10,
    transform: 'rotate(180deg)',
    ' > path': {
      fill: '#b3b3b3',
      stroke: '#b3b3b3'
    }
  },
  '&:hover': {
    borderColor: WHITE,
    background: WHITE,
    color: SILVER_ALUMINIUM
  }
}));

const Next = glamorous(SimpleButton)(({show}) => ({
  visibility: show ? 'visible' : 'hidden',
  color: WHITE,
  fontSize: 15,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.25,
  '& > svg': {
    width: 8,
    height: 13,
    marginLeft: 10,
    ' > path': {
      fill: WHITE,
      stroke: WHITE
    }
  },
  '&:hover': {
    borderColor: '#43a4ff'
  }
}));

const StepsIndicator = glamorous.div({
  borderRadius: 15,
  padding: '12px 10px',
  backgroundColor: ALPHA(FOCUS_BLUE, 0.12),
  display: 'flex',
  alignItems: 'center'
});

const Step = glamorous.div(({seen}) => ({
  width: 8,
  height: 8,
  borderRadius: 40,
  margin: '0 2.5px',
  backgroundColor: ALPHA(FOCUS_BLUE, seen ? 1 : 0.18)
}));

const ButtonContainer = glamorous(SimpleButton)({
  border: `1px solid ${DODGER_BLUE}`,
  borderRadius: 4,
  height: 32,
  fontSize: 18,
  fontWeight: WEIGHT.ULTRA_BOLD,
  textAlign: 'center',
  backgroundColor: FOCUS_BLUE,
  color: WHITE,
  padding: '0 25px',
  minWidth: 'auto',
  letterSpacing: 0,
  [PHONE]: {
    width: '100%',
    height: 58,
    fontSize: 20,
    fontWeight: WEIGHT.BOLD
  }
});

const GithubIconStyled = glamorous(GithubIcon)({
  width: 17,
  height: 17,
  marginRight: 7
});

const DownloadButton = glamorous(SimpleButton)({
  ...BASE_TEXT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 220,
  height: 'auto',
  fontSize: 17,
  padding: 15,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontWeight: WEIGHT.NORMAL,
  lineHeight: '17px',
  color: WHITE,
  margin: 'auto',
  ':focus': {
    color: WHITE
  }
}).withComponent('a');

const CTAContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 16
});

const CloseIconDiv = glamorous.div({
  width: 40,
  height: 40,
  borderRadius: 40,
  cursor: 'pointer',
  position: 'absolute',
  right: -9,
  top: -17,
  backgroundColor: WHITE_SMOKE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > svg > g': {
    fill: SILVER_ALUMINIUM,
    stroke: SILVER_ALUMINIUM
  },
  '&:hover': {
    backgroundColor: GREY
  }
});

const Title = glamorous.h1({
  fontSize: 24,
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  marginBottom: 20,
  fontWeight: WEIGHT.ULTRA_BLACK
});

const Carousel = ({steps, onDismiss}) => {
  const [stepToShow, setStepToShow] = useState(0);
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const onNextClick = () => setStepToShow(stepToShow + 1);
  const onPrevClick = () => setStepToShow(stepToShow - 1);

  return (
    <BaseModal hideTitleBar>
      <Container>
        <CloseIconDiv onClick={onDismiss}>
          <CloseIcon />
        </CloseIconDiv>
        <Title>Introducing The Stack File GitHub App</Title>
        <CTAContainer>
          <DownloadButton
            href={GITHUB_STACK_FILE_MARKETPLACE}
            onClick={() => sendAnalyticsEvent('stack_file_click_install_bot')}
          >
            <GithubIconStyled /> Install the free bot
          </DownloadButton>
        </CTAContainer>

        {steps[stepToShow]}
        <Actions>
          <Previous onClick={onPrevClick} show={stepToShow !== 0}>
            <Arrow />
            Previous
          </Previous>
          <StepsIndicator>
            {steps.map((_, index) => (
              <Step seen={index <= stepToShow} key={index} />
            ))}
          </StepsIndicator>
          {stepToShow !== steps.length - 1 ? (
            <Next onClick={onNextClick} show={stepToShow !== steps.length - 1}>
              Next
              <Arrow />
            </Next>
          ) : (
            <ButtonContainer onClick={onDismiss}>Close</ButtonContainer>
          )}
        </Actions>
      </Container>
    </BaseModal>
  );
};

Carousel.propTypes = {
  steps: PropTypes.array,
  onDismiss: PropTypes.func
};

// We have passed second argument as true for disabling outside click of the modal
export default withPortal(Carousel, true);
